.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.App-header {
	min-height: 10vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-header a {
	font-size: calc(10px + .5vmin);

}

.Body {
	display: flex;
	flex-direction: row;
}

.BodyLeft {
	display: flex;
	flex-direction: column;
}

.BodyRight {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	overflow: hidden;
}

.Footer {
	margin-top: auto;
	padding: 12px;
}

.Footer ul {
	margin: auto;
	width: fit-content;
}

.Footer li:first-child {
	list-style-type: none;
}

.Footer li {
	float: left;
	margin-left: 28px;
}

.VisualizerTray {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.Settings label {
	text-align: right;
	grid-column: 1/2;
}

.Settings input {
	width: 100px;
	grid-column: 2/3;
}

.Settings select {
	grid-column: 2/3;
}

.Settings .FormSection {
	padding: 8px;
}

.Settings .FormHeader {
	padding-top: 8px;
}

.Settings .FormRow {
	display: grid;
	grid-template-columns: 150px 1fr;
	column-gap: 16px;
	row-gap: 16px;
	padding: 2px;
}

.Settings .ThreeButtonRow {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 16px;
	padding: 2px;
}

.Settings .TwoButtonRow {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 16px;
	padding: 2px;
}

.Settings .OneButtonRow {
	display: grid;
	grid-template-columns: 1fr;
	padding: 10px;
}

.Settings a {
	display: block;
	border: 2px solid black;
	background: gray;
	padding: 2px;
	color: white;
	text-decoration: none;
}

.Settings span {
	font-weight: bold;
}

.Block {
	block-size: fit-content;
	border: 2px solid gray;
	margin: 12px 0px 0px 12px;
}

div.Visualizer {
	margin-right: 12px;
	height: 500px;
}

.Instructions {
	text-align: left;
	width: 50vw;
	margin-left: auto;
	margin-right: auto;
	font-size: 20px;
	line-height: 1.6;
}


.Instructions-header {
	min-height: 10vh;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: left;
	text-align: left;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	padding: 24px;
}

.Instructions-header h1 {
	font-size: calc(10px + 2vmin);
}

.Instructions-header a {
	font-size: calc(10px + .5vmin);
}

.Instructions .TOC {
	margin-left: auto;
	margin-right: auto;
	border: 2px solid gray;
	width: fit-content;
	padding: 24px;
}

.Instructions .TOC ul {
	list-style-type: none;
	padding: 0px;
	margin: 0px;
}

.Instructions img {
	max-width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
